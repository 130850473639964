import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    sakti: {},
    saktiToken: false,
    page: false,
    currentPage: 1,
    currentPerPage: 30,
    siaGuid: '',
    siaKegiatanGuid: '',
    siaMap: {},
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    TOGGLE_SAKTI(state, val) {
      state.sakti = val !== undefined ? val : !state.sakti
    },
    SAKTI_TOKEN(state, val) {
      state.saktiToken = val !== undefined ? val : !state.saktiToken
    },
    SET_PAGE(state, val) {
      state.page = val !== undefined ? val : false
    },
    CURRENT_PAGE(state, val) {
      state.currentPage = val !== undefined ? val : 1
    },
    CURRENT_PER_PAGE(state, val) {
      state.currentPerPage = val !== undefined ? val : 30
    },
    APP_GUID(state, val) {
      state.siaGuid = val !== undefined ? val : ''
    },
    KEG_GUID(state, val) {
      state.siaKegiatanGuid = val !== undefined ? val : ''
    },
    SIA_MAP(state, val) {
      state.siaMap = val !== undefined ? val : {}
    },
  },
  actions: {},
}
